"use client"
import { SignIn } from '@clerk/nextjs'
import { useTheme } from 'next-themes'
import { dark, experimental__simple } from '@clerk/themes'
import { FaFilterCircleDollar } from "react-icons/fa6"
import { motion } from "framer-motion"

export default function SignInPage() {
  const { theme, setTheme } = useTheme()
  
  const logoAnimation = {
    scale: [1, 2, 2, 1, 1],
    rotate: [0, 3600],
    borderRadius: ["0%", "0%", "50%", "50%", "0%"]
  }
  const logoAnimationSM = {
    scale: [0.8, 0.8, 0.8, 0.8, 0.8],
    rotate: [0, 3600],
    borderRadius: ["0%", "0%", "50%", "50%", "0%"]
  }

  return (
    <div className='min-h-screen flex flex-col md:grid md:grid-cols-3'>
      {/* Logo section (visible on all screens) */}
      <div className='flex justify-center items-center p-8 bg-gray-100 dark:bg-neutral-800 md:hidden'>
        <motion.div
          animate={logoAnimationSM}
          transition={{
            duration: 5,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: 1,
            repeatDelay: 200
          }}
        >
          <FaFilterCircleDollar className="text-[#db0000] h-24 w-24" />
        </motion.div>
      </div>

      {/* Left section (logo and text, hidden on small screens) */}
      <div className='hidden md:flex flex-col items-center justify-center bg-gray-100 dark:bg-neutral-800 p-8'>
        <motion.div
          whileHover={{ scale: 1.1 }}
          animate={logoAnimation}
          transition={{
            duration: 5,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: 1,
            repeatDelay: 200
          }}
        >
          <FaFilterCircleDollar className="text-[#db0000] h-24 w-24 mb-4" />
        </motion.div>
        <h1 className='text-3xl font-bold mb-4'>Dallaspuram</h1>
        <p className='text-center text-gray-600 dark:text-gray-300 text-xl'>
          
        </p>
      </div>

      {/* Right section (sign-in form) */}
      <div className='flex-1 md:col-span-2 flex items-center justify-center p-4'>
        <div className='w-full max-w-md'>
          <SignIn appearance={{
            baseTheme: theme === "dark" ? dark : experimental__simple,
          }}/>
        </div>
      </div>
    </div>
  )
}
